import React, { Component } from "react";
// import { FormattedMessage } from "react-intl";
// import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
// import Divider from "@mui/material/Divider";
// import Drawer from "@mui/material/Drawer";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import MenuIcon from "@mui/icons-material/Menu";
// import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import logo from "../../detailLogo.png";
// import { Link } from "react-router-dom";

const reviewLabTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#6b7a8f",
      darker: "#053e85",
      contrastText: "#ffcc00",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

// const drawerWidth = 240;

// function UserMenu(props) {
//   let navItems;
//   //   navItems = [
//   //     {
//   //       label: "Home",
//   //       link: "/",
//   //       localeId: "app.header.home.label",
//   //       public: true,
//   //     },
//   //   ];
//   navItems = [];
//   if (props.drawer) {
//     return navItems.map((item) => (
//       <ListItem key={item.label} disablePadding>
//         <Link key={item.label} to={item.link} className={"drawerMenu"}>
//           <FormattedMessage id={item.localeId} defaultMessage={item.label} />
//         </Link>
//       </ListItem>
//     ));
//   } else {
//     return navItems.map((item) => (
//       <Link key={item.label} to={item.link} className={"headerMenu"}>
//         <FormattedMessage id={item.localeId} defaultMessage={item.label} />
//       </Link>
//     ));
//   }
// }

class LayoutMUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  componentDidMount = () => {};

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  render() {
    // const { window } = this.props;
    // const { mobileOpen } = this.state;

    // const drawer = (
    //   <Box onClick={this.handleDrawerToggle} sx={{ textAlign: "center" }}>
    //     <img src={logo} className="App-logo mrkPT1em" alt="logo" />
    //     <Typography variant="h6" sx={{ my: 2 }}>
    //       ReviewLab
    //     </Typography>
    //     <Divider />
    //     <List>
    //       <UserMenu drawer={true} />
    //     </List>
    //   </Box>
    // );

    // const container =
    //   window !== undefined ? () => window().document.body : undefined;

    return (
      <ThemeProvider theme={reviewLabTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          {/* <AppBar component="nav" color="primary">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <img src={logo} className="App-logo" alt="logo" />
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
              >
                ReviewLab
              </Typography>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <UserMenu />
              </Box>
            </Toolbar>
          </AppBar>
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box> */}
          <Container
            maxWidth={false}
            disableGutters={false}
            className={"visitorMainContainer"}
          >
          <Box component="main">
            {this.props.children}
          </Box>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }
}

export default LayoutMUI;

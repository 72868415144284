import React, { Component } from "react";
import SettingsForm from "./settingsForm.component"
import axios from "axios";

export default class LoggedInUser extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/auth/signout/"+this.props.user.id)
      .then((response) => {
        console.log("Chau");
      })
      .catch((error) => {
        console.log(error);
      });
      this.props.updateAppState({ user: {} });
    }

  render() {
    return (
      <div>
        <h1>Hola {this.props.user.username}</h1>
        <SettingsForm user={this.props.user} logout={this.handleClick} />
      </div>
    );
  }
}

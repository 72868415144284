import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import LayoutMUI from "./layoutMUI.component";
import { Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      redirectTo: false,
    };
  }

  componentDidMount() {
    this.props.updateAppState({
      breadcrumbs: [],
    });
  }

  //FIXME create credits page to give credit to freepik
  render() {
    if (this.state.redirectTo) return <Navigate to={this.state.redirectTo} />;
    return (
      <LayoutMUI>
        <Container maxWidth={false} disableGutters>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={1}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="h3" gutterBottom>
                <FormattedMessage id="app.home.title" defaultMessage="" />
              </Typography>
              <Typography paragraph>
                <FormattedMessage id="app.home.text" defaultMessage="" />
              </Typography>
              <Typography paragraph align="center">
                <Button
                  onClick={() => {
                    this.setState({ redirectTo: "/login" });
                  }}
                  variant="contained"
                  color="primary"
                >
                  <FormattedMessage id="word.getStarted" defaultMessage="" />
                </Button>
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                <FormattedMessage id="word.noCreditCardReq" defaultMessage="" />
                <br />
                <FormattedMessage id="word.noTimeLimit" defaultMessage="" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} sx={{ textAlign: "center" }}>
              <Carousel
                swipeable
                emulateTouch
                autoPlay
                infiniteLoop
                interval={5000}
                showStatus={false}
                showThumbs={false}
                showArrows={false}
              >
                <div>
                  <img
                    src={"assets/images/home/slide01.jpg"}
                    alt={"Feedback"}
                  />
                </div>
                <div>
                  <img
                    src={"assets/images/home/slide02.jpg"}
                    alt={"Feedback"}
                  />
                </div>
                <div>
                  <img
                    src={"assets/images/home/slide03.jpg"}
                    alt={"Feedback"}
                  />
                </div>
                <div>
                  <img
                    src={"assets/images/home/slide04.jpg"}
                    alt={"Feedback"}
                  />
                </div>
              </Carousel>
            </Grid>
            <Grid item xs={12} sm={1}>
              &nbsp;
            </Grid>
          </Grid>
        </Container>
      </LayoutMUI>
    );
  }
}

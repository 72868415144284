import React, { Component, createRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { getValueFromObjectsArray } from "../../utilities/helpers";
import {
  DatePicker,
  MobileTimePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import FormNavigation from "./formNavigation.component";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";

function CheckboxItems(props) {
  let multiple = getValueFromObjectsArray("multiple", props.question.config);
  if (multiple) {
    return (
      <FormGroup>
        {props.question.options.map((option) => (
          <FormControlLabel
            key={"lbl" + option.id}
            control={
              <Checkbox
                checked={props.selections.includes(option.text)}
                onChange={props.handleChange}
                key={"chk" + option.id}
                inputProps={{
                  "data-question-key": option.text,
                  "data-question-multiple": true,
                }}
              />
            }
            label={option.text}
          />
        ))}
      </FormGroup>
    );
  } else {
    return (
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">{props.text}</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {props.question.options.map((option) => (
            <FormControlLabel
              value={option.text}
              key={"lbl" + option.id}
              control={
                <Radio
                  value={option.text}
                  checked={props.selections.includes(option.text)}
                  onChange={props.handleChange}
                  key={"rad" + option.id}
                  inputProps={{
                    "data-question-key": option.text,
                    "data-question-multiple": false,
                  }}
                />
              }
              label={option.text}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

function ControlledDatePicker(props) {
  let initialValue = moment();
  if (props.response && props.response.value) {
    initialValue = moment(props.response.value, "YYYY-MM-DD");
  }
  return (
    <DatePicker
      value={initialValue}
      onChange={props.onChange}
      format={"YYYY-MM-DD"}
    />
  );
}

//TODO verify if storing like this, we respect local UTF settings
function ControlledTimePicker(props) {
  let initialValue = moment();
  if (props.response && props.response.value) {
    initialValue = moment(props.response.value, "YYYY-MM-DDTHH:mm");
  }
  return (
    <MobileTimePicker
      value={initialValue}
      onChange={props.onChange}
      format={"HH:mm"}
      ampm={false}
    />
  );
}

function ControlledDateTimePicker(props) {
  let initialValue = moment();
  if (props.response && props.response.value) {
    initialValue = moment(props.response.value, "YYYY-MM-DDTHH:mm");
  }
  return (
    <MobileDateTimePicker
      value={initialValue}
      onChange={props.onChange}
      format={"YYYY-MM-DD HH:mm"}
      ampm={false}
    />
  );
}

export default class Question extends Component {
  constructor() {
    super();
    this.state = {
      position: null,
    };
    this.inputRef = createRef();
  }

  componentDidMount() {
    let initialPosition = this.props.position ? this.props.position : false;
    this.setState({ position: initialPosition });
  }

  componentDidUpdate() {
    // console.log("Llamaron a question.component componentDidUpdate");
    // console.log(this.props.files);
    // if (this.props.response) {
    if (this.props.response || this.state.position) {
      let response = this.props.response;
      if (this.state.position) response.value = this.state.position;
      const formData = new FormData();
      for (const key in response) {
        formData.append(key, response[key]);
      }
      // formData.set('formId', "cazuela");
      if (this.props.files) {
        formData.append('file', this.props.files[0]);
        // console.log(this.props.files[0]);
      }
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "/api/responses/" +
            this.props.formId +
            "/" +
            this.props.question.id +
            "/" +
            Cookies.get("uHash"),
            formData
            // response
        )
        .then((response) => {
          if (response.status === 200) {
          } else {
          }
        })
        .catch((error) => {
          // TODO handle UI error
          console.log(error);
        });
    }
  }

  render() {
    let tag = null;
    if (this.props.question) {
      let question = this.props.question;
      let rawValue = this.props.response ? this.props.response.value : "";
      tag = (
        <TextField
          id="QuestionTypeLabel"
          inputProps={{ name: "value" }}
          label="Type your answer"
          variant="outlined"
          value={rawValue}
          onChange={this.props.handleChange}
          fullWidth
        />
      );
      switch (question.definition.label) {
        case "SHORT_TEXT":
        case "LONG_TEXT":
          break;
        case "RATING":
          tag = (
            <Rating
              name={"value"}
              onChange={this.props.handleChange}
              value={isNaN(rawValue) ? 0 : parseInt(rawValue)}
              size={"large"}
            />
          );
          break;
        case "NUMBER":
          tag = (
            <TextField
              id="QuestionTypeLabel"
              inputProps={{ name: "value", type: "number" }}
              label="Type your answer"
              variant="outlined"
              value={rawValue}
              onChange={this.props.handleChange}
              fullWidth
            />
          );
          break;
        case "MULTIPLE_CHOICE":
          tag = (
            <CheckboxItems
              question={question}
              selections={this.props.selections}
              handleChange={this.props.handleChange}
            />
          );
          break;
        case "DATE":
          tag = (
            <ControlledDatePicker
              response={this.props.response}
              onChange={this.props.handleChange}
            />
          );
          break;
        case "TIME":
          tag = (
            <ControlledTimePicker
              response={this.props.response}
              onChange={this.props.handleChange}
            />
          );
          break;
        case "DATE_TIME":
          tag = (
            <ControlledDateTimePicker
              response={this.props.response}
              onChange={this.props.handleChange}
            />
          );
          break;
        case "POSITION":
          tag = (
            <>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="positionInput">Position</InputLabel>
                <OutlinedInput
                  id="positionInput"
                  type={"text"}
                  inputRef={this.inputRef}
                  value={rawValue}
                  onChange={this.props.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.props.getPosition}
                        edge="end"
                        color="info"
                      >
                        <MyLocationIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Position"
                />
              </FormControl>
            </>
          );
          break;
        case "FILE":
          tag = (
            <TextField
              id="QuestionTypeLabel"
              inputProps={{ name: "value", type: "file" }}
              label="Upload a file"
              variant="outlined"
              onChange={this.props.handleChange}
              fullWidth
            />
          );
          break;
        default:
      }
    }

    return (
      <Grid container item className={"qContainer"}>
        <Grid item sx={{ width: "100%" }}>
          <form
            onSubmit={this.props.handleSubmit}
            encType={"multipart/form-data"}
          >
            <h3>{this.props.question.text}</h3>
            {tag}
          </form>
        </Grid>
        <Grid item>
          <FormNavigation
            handleFormNav={this.props.handleFormNav}
            okButtonLabelId={this.props.okButtonLabelId}
          />
        </Grid>
      </Grid>
    );
  }
}

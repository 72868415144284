import React, { Component } from "react";
import LayoutMUI from "./layoutMUI.component";
import { FormattedMessage, injectIntl } from "react-intl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";

const tiers = [
  {
    title: "pricing.plan1.title",
    subheader: "pricing.plan1.subheader",
    price: "9",
    description: [
      { icon: "check", text: "pricing.plan1.desc.line1" },
      { icon: "check", text: "pricing.plan1.desc.line2" },
      { icon: "check", text: "pricing.plan1.desc.line3" },
      { icon: "check", text: "pricing.plan1.desc.line4" },
    ],
    buttonText: "pricing.plan1.button.text",
    buttonVariant: "outlined",
    buttonURL: "/signup",
  },
  {
    title: "pricing.plan2.title",
    subheader: "pricing.plan2.subheader",
    price: "19",
    description: [
      { icon: "check", text: "pricing.plan2.desc.line1" },
      { icon: "check", text: "pricing.plan2.desc.line2" },
      { icon: "check", text: "pricing.plan2.desc.line3" },
      { icon: "add", text: "pricing.plan2.desc.line4" },
      { icon: "add", text: "pricing.plan2.desc.line5" },
    ],
    buttonText: "pricing.plan2.button.text",
    buttonVariant: "contained",
    buttonURL: "/signup",
  },
  {
    title: "pricing.plan3.title",
    subheader: "pricing.plan3.subheader",
    price: "39",
    description: [
      { icon: "check", text: "pricing.plan3.desc.line1" },
      { icon: "check", text: "pricing.plan3.desc.line2" },
      { icon: "check", text: "pricing.plan3.desc.line3" },
      { icon: "add", text: "pricing.plan3.desc.line4" },
    ],
    buttonText: "pricing.plan3.button.text",
    buttonVariant: "outlined",
    buttonURL: "/signup",
  },
  {
    title: "pricing.plan4.title",
    subheader: "pricing.plan4.subheader",
    price: "",
    description: [
      { icon: "check", text: "pricing.plan4.desc.line1" },
      { icon: "check", text: "pricing.plan4.desc.line2" },
      { icon: "check", text: "pricing.plan4.desc.line3" },
      { icon: "check", text: "pricing.plan4.desc.line4" },
    ],
    buttonText: "pricing.plan4.button.text",
    buttonVariant: "outlined",
    buttonURL: "/signup",
  },
];

class Pricing extends Component {
  render() {
    const { formatMessage } = this.props.intl;
    return (
    <LayoutMUI>
      {/* Hero unit */}
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          <FormattedMessage id={"pricing.title"} defaultMessage={"Pricing"} />
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="h5"
        >
          <FormattedMessage
            id={"pricing.subtitle1"}
            defaultMessage={"Select the pricing plan that suits your needs"}
          />
        </Typography>

        <Typography
          variant="p"
          align="center"
          color="text.secondary"
          component="p"
        >
          <FormattedMessage
            id={"pricing.subtitle2"}
            defaultMessage={"or experience our "}
          />{" "}
          <Link href="/signup">
            {" "}
            <FormattedMessage id={"pricing.plan.free.title"} defaultMessage={"Free Plan"} />
          </Link>
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} sm={6} md={3}>
              <Card>
                <CardHeader
                  title={formatMessage({ id: tier.title })}
                  subheader={(tier.subheader==="pricing.plan2.subheader")?formatMessage({ id: tier.subheader }):""}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "pricing.plan2.title" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  {tier.description.map((line) => (
                    <Typography
                      component="p"
                      align="left"
                      key={line.text}
                      className="priceDescription"
                    >
                      {line.icon === "check" ? (
                        <CheckIcon
                          fontSize={"small"}
                          sx={{ marginBottom: "-4px" }}
                          color="success"
                        />
                      ) : (
                        <AddIcon
                          fontSize={"small"}
                          sx={{ marginBottom: "-4px" }}
                          color="success"
                        />
                      )}{" "}
                      <FormattedMessage id={line.text} />
                    </Typography>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    href={tier.buttonURL}
                  >
                    <FormattedMessage id={tier.buttonText} />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </LayoutMUI>
  );
}}

export default injectIntl(Pricing);
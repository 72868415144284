import React, { Component } from "react";
import SummaryQuestionItem from "./summaryQuestionItem.component";
import FormSubMenu from "./formSubMenu.component";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

function SummaryList(props) {
  if (props.form) {
    return props.form.questions.map((d) => (
      <SummaryQuestionItem
        key={d.id}
        formId = {props.form.id}
        question={d}
        responses={props.responses.filter(
          (response) => response.questionId === d.id
        )}
      />
    ));
  }
  return <></>;
}

export default class Summary extends Component {
  render() {
    return (
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
            <Typography variant="h4">{this.props.form.name}</Typography>
            <FormSubMenu form={this.props.form} />
            <Typography paragraph>Visits {this.props.visits}</Typography>
          </Grid>
          <SummaryList
            form={this.props.form}
            responses={this.props.responses}
          />
        </Grid>
      </Container>
    );
  }
}

import React, { Component, useState } from "react";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMap
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import mrkricon from "leaflet/dist/images/marker-icon.png";
import mrkriconShadow from "leaflet/dist/images/marker-shadow.png";

// eslint-disable-next-line
function LocationMarker() {
  const [position, setPosition] = useState(null);
  const map = useMapEvents({
    click() {
      map.locate();
    },
    locationfound(e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

function MarkersList(props) {
  const map = useMap()
  if (props.responses) {
    const mapBounds = props.responses.map(item => {
      const [lat, lng] = item.value.split(", ");
      return {lat, lng};
    });
    map.fitBounds(mapBounds);
    return mapBounds.map((d) => (
      <Marker key={d.lat+"-"+d.lng} position={[d.lat, d.lng]}>
        <Popup>
          <span>
          {d.lat+"-"+d.lng}
          </span>
        </Popup>
      </Marker>
    ));
  }
  return <li>Nada aun</li>;
}

export default class Map extends Component {
  constructor() {
    super();
    this.state = {
      responses: null,
      map: null,
    };
  }

  componentDidMount() {}

  render() {
    const centerPoint = [-38.0190009, -57.5263994];
    let DefaultIcon = L.icon({
      iconUrl: mrkricon,
      shadowUrl: mrkriconShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
    });
    L.Marker.prototype.options.icon = DefaultIcon;

    return (
      <div>
        <MapContainer center={centerPoint} zoom={17} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkersList responses={this.props.responses} />
          {/* <LocationMarker /> */}
        </MapContainer>
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { FormattedMessage, injectIntl } from "react-intl";
import LayoutMUI from "./layoutMUI.component";
import QuestionListItem from "./questionListItem.component";
import QuestionDetail from "./questionDetail.component";
import QuestionSettings from "./questionSettings.component";
import DefinitionListItem from "./definitionListItem.component";
import AppContext from "../utilities/context";
import withRouter from "../utilities/withRouter";
import FormSubMenu from "./formSubMenu.component";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";

function DefinitionsModal(props) {
  const context = React.useContext(AppContext);

  if (context.definitions) {
    let htmlBody = [];
    const typeMap = {};
    for (let i = 0; i < context.definitions.length; i++) {
      const type = context.definitions[i].type;
      if (!typeMap[type]) {
        typeMap[type] = [];
      }
      typeMap[type].push(context.definitions[i]);
    }

    for (const type in typeMap) {
      let defTitle = (
        <Typography key={"defTit_" + type} variant="h5">
          <FormattedMessage
            id={"definition.type." + type}
            defaultMessage={type}
          />
        </Typography>
      );
      let defBody = [];
      for (const item in typeMap[type]) {
        defBody.push(
          <DefinitionListItem
            key={"defType_" + typeMap[type][item].label}
            definition={typeMap[type][item]}
            createQuestion={props.createQuestion}
          />
        );
      }
      htmlBody.push(
        <Grid key={"defItem" + type} item xs={6}>
          {defTitle}
          {defBody}
        </Grid>
      );
    }

    return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle>Question Types</DialogTitle>
        <DialogContent className="mrkPT03em">
          <Grid container direction={"column"} spacing={2}>
            {htmlBody}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>{props.closeLabel || "Close"}</Button>
        </DialogActions>
      </Dialog>
    );
  }
  return <></>;
}

function FormProfile(props) {
  if (props.form) {
    return (
      <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
        <Typography variant="h4">{props.form.name}</Typography>
        <FormSubMenu form={props.form} />
      </Grid>
    );
  }
  return <></>;
}

function QuestionList(props) {
  if (props.form) {
    return props.form.questions.map((d) => (
      <QuestionListItem
        setActiveQuestion={props.setActiveQuestion}
        key={d.id}
        question={d}
      />
    ));
  }
  return <></>;
}

function QuestionBoard(props) {
  if (props.form) {
    return (
      <QuestionDetail
        questions={props.form.questions}
        activeQuestion={props.activeQuestion}
        handleChange={props.handleChange}
        handleOptionsChange={props.handleOptionsChange}
        createOption={props.createOption}
        removeOption={props.removeOption}
      />
    );
  }
  return <></>;
}

class FormDetail extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      form: null,
      activeQuestion: null,
      showModal: false,
      open: false,
    };
    this.getForm = this.getForm.bind(this);
    this.setActiveQuestion = this.setActiveQuestion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOptionsChange = this.handleOptionsChange.bind(this);
    this.createQuestion = this.createQuestion.bind(this);
    this.createOption = this.createOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  setOpen = (status) => {
    this.setState({ open: status });
  };

  showModal = (e) => {
    this.setOpen(true);
  };

  hideModal = (e) => {
    this.setOpen(false);
  };

  componentDidMount() {
    this.getForm();
    this.props.updateAppState({
      breadcrumbs: [
        { label: "Home", url: "/" },
        { label: "Workspace", url: "/forms" },
      ],
    });
  }

  setActiveQuestion(id) {
    this.setState({
      activeQuestion: id,
    });
    console.log("setActiveQuestion: "+id);
  }

  handleChange(event, additionalData) {
    if (additionalData) console.log(additionalData);
    const questionIndex = this.state.form.questions.findIndex(
      (question) => question.id === parseInt(this.state.activeQuestion)
    );
    let formData = this.state.form;
    let targetValue = event.target.value;
    if (event.target.name === "config") {
      let configTarget = additionalData["data-config-target"];
      let configPrevious = JSON.parse(additionalData["data-config-previous"]);
      targetValue = JSON.parse(event.target.value);
      let configNew = [];
      configPrevious.forEach((element) => {
        let configItem = element;
        if (element.type === configTarget)
          configItem.value = event.target.value;
        configNew.push(configItem);
      });
      targetValue = configNew;
    }
    formData.questions[questionIndex][event.target.name] = targetValue;

    this.setState({
      form: formData,
    });
  }

  handleOptionsChange(event) {
    let formData = this.state.form;
    const questionIndex = formData.questions.findIndex(
      (question) => question.id === parseInt(this.state.activeQuestion)
    );
    let question = formData.questions[questionIndex];
    const optionIndex = question.options.findIndex(
      (option) =>
        option.id === parseInt(event.target.getAttribute("data-option-id"))
    );
    formData.questions[questionIndex].options[optionIndex].text =
      event.target.value;
    this.setState({
      form: formData,
    });
  }

  getForm(activeQuestion) {
    axios
      .get(
        process.env.REACT_APP_API_URL + "/api/forms/" + this.props.params.hash
      )
      .then((response) => {
        this.setState({ form: response.data }, () => {
          if (activeQuestion) {
            this.setState({ activeQuestion: activeQuestion });
          }
        });
      })
      .catch((error) => {
        this.setState({ form: null });
        console.log(error);
      });
  }

  createQuestion(e) {
    const context = this.context;
    const definition = context.definitions.find(
      (obj) => obj.id === parseInt(e.target.value)
    );

    let question = {
      text: "",
      description: null,
      required: 0,
      formId: this.state.form.id,
      definitionId: e.target.value,
      order: this.state.form.questions.length,
    };
    //FIXME posible bug me salto al crear una pregunta pero no pude repetirlo. Estar atento
    // Uncaught TypeError: Cannot read properties of undefined (reading 'config')
    // Cuando ejecuto definitionsSeed se borra definitionId de la tabla questions
    if (definition.config) {
      let questionConfig = [];
      definition.config.forEach(function (item, index) {
        let itemConfig = {};
        itemConfig.type = item.type;
        itemConfig.value = item.options[0].value;
        questionConfig.push(itemConfig);
      });
      if (questionConfig) question.config = questionConfig;
    }

    axios
      .post(process.env.REACT_APP_API_URL + "/api/questions/", question)
      .then((response) => {
        if (response.status === 200) {
          this.getForm(response.data.id);
          this.hideModal();
        } else {
        }
      })
      .catch((error) => {
        // TODO handle UI error
        console.log("login error: ");
        console.log(error);
      });
  }

  deleteQuestion(e) {
    axios
      .delete(
        process.env.REACT_APP_API_URL +
          "/api/questions/" +
          this.state.activeQuestion
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ activeQuestion: null }, () => {
            this.getForm();
          });
        } else {
        }
      })
      .catch((error) => {
        // TODO handle UI error
        console.log("login error: ");
        console.log(error);
      });
  }

  createOption(e) {
    let option = {
      text: "",
      order: 1,
      questionId: this.state.activeQuestion,
      active: 1,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/api/options/", option)
      .then((response) => {
        if (response.status === 200) {
          this.getForm(this.state.activeQuestion);
        } else {
        }
      })
      .catch((error) => {
        // TODO handle UI error
        console.log("login error: ");
        console.log(error);
      });
  }

  removeOption(optionId) {
    axios
      .delete(process.env.REACT_APP_API_URL + "/api/options/" + optionId)
      .then((response) => {
        if (response.status === 200) {
          this.getForm(this.state.activeQuestion);
        } else {
        }
      })
      .catch((error) => {
        // TODO handle UI error
        console.log("login error: ");
        console.log(error);
      });
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <LayoutMUI>
        <FormProfile form={this.state.form} className="formProfile" />
        <Container maxWidth={false} disableGutters={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DefinitionsModal
                createQuestion={this.createQuestion}
                onClose={this.hideModal}
                open={this.state.open}
                formatMessage={formatMessage}
              />
              <Button className="btn btn-primary m-1" onClick={this.showModal}>
                + Create Question
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Card>
                <CardContent>
                  <Grid container direction={"column"} spacing={2}>
                    <QuestionList
                      setActiveQuestion={this.setActiveQuestion}
                      form={this.state.form}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Grid container direction={"column"} spacing={2}>
                    <QuestionBoard
                      form={this.state.form}
                      activeQuestion={this.state.activeQuestion}
                      handleChange={this.handleChange}
                      handleOptionsChange={this.handleOptionsChange}
                      createOption={this.createOption}
                      removeOption={this.removeOption}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Card>
                <CardContent>
                  <QuestionSettings
                    form={this.state.form}
                    activeQuestion={this.state.activeQuestion}
                    handleChange={this.handleChange}
                    deleteQuestion={this.deleteQuestion}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </LayoutMUI>
    );
  }
}

export default withRouter(injectIntl(FormDetail));

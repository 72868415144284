import React, { Component } from "react";
import axios from "axios";
import LayoutMUI from "./layoutMUI.component";
import Question from "./question.component";
import withRouter from "../../utilities/withRouter";
import Cookies from "js-cookie";
import { createHash, logUserActivity } from "../../utilities/helpers";
import Grid from "@mui/material/Grid";
import { FormattedMessage, injectIntl } from "react-intl";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function FormModal(props) {
  if (props) {
    return (
      <Dialog open={props.open} onClose={props.handleClose} maxWidth={"md"}>
        <DialogTitle>
          <FormattedMessage
            id="response.submitted.title"
            defaultMessage="Thanks"
          />
        </DialogTitle>
        <DialogContent className="mrkPT03em">
          <Grid container direction={"column"} spacing={2}>
            <Grid item>
              <FormattedMessage
                id="response.submitted.body"
                defaultMessage="Thanks"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>
            {props.closeLabel || "Close"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return <></>;
}

function FormSummary(props) {
  let fTitle = null;
  let fDesc = null;
  if (
    props.form &&
    (props.form.showTitle === true || props.form.showDescription === true)
  ) {
    if (props.form.showTitle) {
      fTitle = (
        <Typography variant="h4" align="center">
          {props.form.name}
        </Typography>
      );
    }
    if (props.form.showDescription) {
      fDesc = (
        <Typography variant="h6" align="center">
          {props.form.description}
        </Typography>
      );
    }
    return (
      <div className="formSummary">
        {fTitle}
        {fDesc}
      </div>
    );
  }
  return <></>;
}

function QuestionData(props) {
  // if (props.form && props.submitted === false) {
  if (props.form) {
    let question = props.form.questions[props.activeQuestionIndex];
    let response = null;
    const responseIndex = props.responses.findIndex(
      (response) => response.questionId === parseInt(question.id)
    );
    if (responseIndex >= 0) {
      response = props.responses[responseIndex];
    }
    let okButtonLabelId = "response.done.label";
    if (
      parseInt(props.activeQuestionIndex) ===
      parseInt(props.form.questions.length - 1)
    )
      okButtonLabelId = "response.submit.label";

    return (
      <Question
        question={question}
        handleChange={props.handleChange}
        handleSubmit={props.handleSubmit}
        response={response}
        formId={props.form.id}
        selections={props.selections}
        getPosition={props.getPosition}
        position={props.position}
        files={props.files}
        handleFormNav={props.handleFormNav}
        okButtonLabelId={okButtonLabelId}
      />
    );
  }
  return <></>;
}

class Response extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: null,
      activeQuestionIndex: 0,
      responses: [],
      selections: [],
      position: false,
      files: null,
      activeResponse: null,
      showAlert: false,
      alertMessage: null,
      progress: 0,
      submitted: false,
      modalOpen: false,
    };
    this.getForm = this.getForm.bind(this);
    this.handleFormNav = this.handleFormNav.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkMultiple = this.checkMultiple.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.positionSuccess = this.positionSuccess.bind(this);
    this.positionError = this.positionError.bind(this);
  }

  componentDidMount() {
    this.verifyCookie();
    this.getForm();
  }

  verifyCookie() {
    let activeCookie = Cookies.get("uHash");
    if (activeCookie == null) {
      Cookies.set("uHash", createHash(48), { expires: 30 });
    }
  }

  hideAlert = (e) => {
    this.setState({ showAlert: false, alertMessage: null });
  };

  getForm() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/api/forms/response/" +
          this.props.params.hash
      )
      .then((response) => {
        this.setState({ form: response.data }, () => {
          this.getResponses();
          logUserActivity({
            visitorHash: Cookies.get("uHash"),
            source: window.location.pathname,
            action: "VISIT",
            formId: response.data.id,
          });
        });
      })
      .catch((error) => {
        this.setState({ form: null });
        console.log(error);
      });
  }

  getResponses() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/api/responses/" +
          this.state.form.id +
          "/" +
          Cookies.get("uHash")
      )
      .then((response) => {
        let responses = response.data;
        this.setState({ responses: responses }, () => {
          this.checkMultiple();
          this.setActiveResponseFromPrevious();
        });
      })
      .catch((error) => {
        this.setState({ responses: [] });
        console.log(error);
      });
  }

  setActiveResponseFromPrevious() {
    let question = this.state.form.questions[this.state.activeQuestionIndex];
    let response = null;
    let totalQuestions = parseInt(this.state.form.questions.length);
    let progress = Math.round(
      (100 / totalQuestions) * (parseInt(this.state.activeQuestionIndex) + 1)
    );
    const responseIndex = this.state.responses.findIndex(
      (response) => response.questionId === parseInt(question.id)
    );
    if (responseIndex >= 0) {
      response = this.state.responses[responseIndex].value;
    }
    this.setState({
      activeResponse: response,
      progress: progress,
    });
  }

  handleFormNav(direction) {
    let submitted = false;
    let currentQuestionIndex = parseInt(this.state.activeQuestionIndex);
    let question = this.state.form.questions[this.state.activeQuestionIndex];
    const { formatMessage } = this.props.intl;

    if (direction) {
      if (currentQuestionIndex < this.state.form.questions.length - 1) {
        if (
          question.required &&
          (this.state.activeResponse === null ||
            this.state.activeResponse.trim() === "")
        ) {
          this.setState({
            showAlert: true,
            alertMessage: formatMessage({ id: "response.alert.required" }),
          });
        } else {
          currentQuestionIndex++;
          this.hideAlert();
        }
      } else {
        this.setOpen(true);
        submitted = true;
      }
    } else {
      if (currentQuestionIndex > 0) {
        currentQuestionIndex--;
        this.hideAlert();
      }
    }
    this.setState(
      {
        activeQuestionIndex: currentQuestionIndex,
        submitted: submitted,
      },
      () => {
        this.checkMultiple();
        this.setActiveResponseFromPrevious();
      }
    );
  }

  checkMultiple() {
    let question = this.state.form.questions[this.state.activeQuestionIndex];
    let isMultipleChoice = false;
    switch (question.definition.label) {
      case "SHORT_TEXT":
      case "LONG_TEXT":
      case "NUMBER":
      case "DATE":
      case "TIME":
      case "DATE_TIME":
      case "POSITION":
        break;
      case "MULTIPLE_CHOICE":
        isMultipleChoice = true;
        break;
      default:
    }
    if (isMultipleChoice) {
      let response = null;
      let responseIndex = this.state.responses.findIndex(
        (response) => response.questionId === parseInt(question.id)
      );
      if (responseIndex > -1) {
        response = this.state.responses[responseIndex];
        this.setState({
          selections: response.value,
        });
      }
    }
  }

  handleChange(event) {
    let nextResponses = [...this.state.responses];
    let question = this.state.form.questions[this.state.activeQuestionIndex];
    let isMultipleChoice = false;
    let processedValue = null;
    switch (question.definition.label) {
      case "SHORT_TEXT":
      case "LONG_TEXT":
        break;
      case "NUMBER":
        break;
      case "POSITION":
        break;
      case "FILE":
        this.setState({ files: event.target.files });
        break;
      case "MULTIPLE_CHOICE":
        isMultipleChoice = true;
        break;
      case "DATE":
        processedValue = event.format("YYYY-MM-DD");
        break;
      case "TIME":
        processedValue = event.format("YYYY-MM-DDTHH:mm");
        break;
      case "DATE_TIME":
        processedValue = event.format("YYYY-MM-DDTHH:mm");
        break;
      default:
    }
    if (isMultipleChoice) {
      let isMultiple = event.target.getAttribute("data-question-multiple");
      let selectedValue = event.target.getAttribute("data-question-key");
      isMultiple = isMultiple === "true";
      if (isMultiple) {
        let sel = this.state.selections;
        //TODO improve the store of array values in response.value table ()
        if (typeof sel === "string") sel = sel.split(",");

        let find = sel.indexOf(selectedValue);
        if (find > -1) {
          sel.splice(find, 1);
        } else {
          sel.push(selectedValue);
        }
        this.setState({
          selections: sel,
        });
        event.target.value = sel;
      } else {
        if (event.target.type === "radio") {
          let sel = [];
          sel.push(selectedValue);
          this.setState({
            selections: sel,
          });
          // event.target.value = sel;
        }
      }
    }

    const responseIndex = nextResponses.findIndex(
      (response) => response.questionId === parseInt(question.id)
    );
    let response;
    let nextForm = this.state.form;
    if (responseIndex >= 0) {
      response = nextResponses[responseIndex];
      response.value = processedValue ? processedValue : event.target.value;
      nextForm.responses = nextResponses;
    } else {
      response = {
        questionId: question.id,
        formId: this.state.form.id,
        value: processedValue ? processedValue : event.target.value,
        visitorHash: Cookies.get("uHash"),
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      nextResponses = [...nextResponses, response];
      nextForm.responses = nextResponses;
    }
    this.setState({
      form: nextForm,
      responses: nextResponses,
      activeResponse: processedValue ? processedValue : event.target.value,
    });
  }

  handleSubmit(event) {
    //we are not using this function. We are using handleChange for processing the responses
    event.preventDefault();
  }

  //TODO verify the getPosition workflow. Maybe refactor needed
  getPosition() {
    const positionOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition(
      this.positionSuccess,
      this.positionError,
      positionOptions
    );
  }

  positionSuccess(pos) {
    const crd = pos.coords;
    let positionValue = crd.latitude + ", " + crd.longitude;
    const event = { target: { value: positionValue } };
    this.setState({ position: positionValue }, this.handleChange(event));
  }

  positionError(err) {
    this.setState({ position: null });
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  setOpen = (status) => {
    this.setState({ modalOpen: status });
  };

  handleClose = () => {
    this.setOpen(false);
  };

  render() {
    return (
      <LayoutMUI>
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={this.state.progress} />
        </Box>
        <Collapse in={this.state.showAlert}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  this.hideAlert();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <p>{this.state.alertMessage}</p>
          </Alert>
        </Collapse>
        <FormSummary form={this.state.form} />
        <Grid container direction={"column"} spacing={2} className="visitor">
          <QuestionData
            activeQuestionIndex={this.state.activeQuestionIndex}
            form={this.state.form}
            responses={this.state.responses}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            selections={this.state.selections}
            getPosition={this.getPosition}
            position={this.state.position}
            files={this.state.files}
            handleFormNav={this.handleFormNav}
            submitted={this.state.submitted}
          />
        </Grid>
        <FormModal open={this.state.modalOpen} handleClose={this.handleClose} />
      </LayoutMUI>
    );
  }
}

export default injectIntl(withRouter(Response));

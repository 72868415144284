import React, { Component } from "react";
import LayoutMUI from "../layoutMUI.component";
import withRouter from "../../utilities/withRouter";
import { createHash } from "../../utilities/helpers";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import ExploreIcon from "@mui/icons-material/Explore";
import Typography from "@mui/material/Typography";

function Rumbo(props) {
  if (props.coords) {
    return (
      <div style={{ textAlign: "center" }}>
        <Typography variant="h4">Rumbo: {props.bearing}</Typography>
        <Typography variant="p">{props.message}</Typography>
      </div>
    );
  }
  return <></>;
}

function Log(props) {
  return (
    <div
      id="logContainer"
      style={{
        width: "100%",
        height: "30vh",
        border: "1px solid black",
        overflowY: "scroll",
      }}
    >
      {props.log}
    </div>
  );
}

class Position extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "Sin Datos",
      watchId: null,
      coords: null,
      bearing: null,
      targetBearing: 90,
      tolerance: 15,
      log: [],
      sound: true,
      watching: false,
    };
    this.successPos = this.successPos.bind(this);
    this.errorPos = this.errorPos.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  toogleWatchPosition(watching, watchId) {
    const audio440 = document.getElementById("tone440Hz");
    const audio1000 = document.getElementById("tone1kHz");
    if (watching === false) {
      let id;
      let options;

      options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      id = navigator.geolocation.watchPosition(
        this.successPos,
        this.errorPos,
        options
      );
      this.setState({ watchId: id, watching: true });
      audio440.play();
      audio1000.stop();
    } else {
      navigator.geolocation.clearWatch(watchId);
      this.setState({ watchId: null, watching: false, log: [] });
      audio440.stop();
      audio1000.stop();
    }
  }

  toogleSound(sound) {
    const newStatus = sound === false ? true : false;
    this.setState({ sound: newStatus });
  }

  setBearing(bearing) {
    this.setState({ targetBearing: bearing });
  }

  successPos(pos) {
    const coords = pos.coords;
    const tolerance = this.state.tolerance;
    const audio440 = document.getElementById("tone440Hz");
    const audio1000 = document.getElementById("tone1kHz");
    const sound = this.state.sound;
    let prevLog = [ ...this.state.log ];

    this.setState({ coords: coords });
    this.setState({ bearing: coords.heading });

    const activeBearing = Math.floor((parseInt(coords.heading) + 360) % 360);
    let minLimit = parseInt(this.state.targetBearing) - parseInt(tolerance);
    let maxLimit = parseInt(this.state.targetBearing) + parseInt(tolerance);

    if (activeBearing >= minLimit && activeBearing <= maxLimit) {
      this.setState({ message: "Correcto" });
      audio440.stop();
      audio1000.stop();
    } else {
      if (activeBearing <= minLimit) {
        this.setState({ message: "Menor" });
        if (sound) audio1000.play();
      } else {
        this.setState({ message: "Mayor" });
        if (sound) audio440.play();
      }
    }
    prevLog.push(
      <div key={createHash(10)}>
        {"Bearing: " +
          activeBearing +
          " - " +
          this.state.message +
          ": " +
          this.state.targetBearing +
          " - Tol: " +
          this.state.tolerance}
      </div>
    );
    this.setState({ log: prevLog });
    var myDiv = document.getElementById("logContainer");
    myDiv.scrollTop = myDiv.scrollHeight;
    console.log(prevLog);
  }

  errorPos(err) {
    console.error(`ERROR(${err.code}): ${err.message}`);
  }

  handleChange(event) {
    let number = isNaN(event.target.value) ? "0" : event.target.value;
    this.setState({
      [event.target.name]: number,
    });
  }

  render() {
    return (
      <LayoutMUI>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="targetBearing"
              onChange={this.handleChange}
              value={this.state.targetBearing}
              fullWidth
              label={"Bearing"}
          />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="tolerance"
              onChange={this.handleChange}
              defaultValue={this.state.tolerance}
              fullWidth
              label={"Tolerance"}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                this.toogleWatchPosition(
                  this.state.watching,
                  this.state.watchId
                );
              }}
              startIcon={<NotStartedIcon />}
              variant={"contained"}
              color={"success"}
              fullWidth
            >
              Start
              <br />
              Stop
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                this.toogleSound(this.state.sound);
              }}
              startIcon={<MusicNoteIcon />}
              variant={"contained"}
              color={"secondary"}
              fullWidth
            >
              Toggle
              <br />
              Sound
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                this.setBearing(this.state.bearing);
              }}
              startIcon={<ExploreIcon />}
              variant={"contained"}
              color={"info"}
              fullWidth
            >
              Set
              <br />
              Course
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Rumbo
              coords={this.state.coords}
              message={this.state.message}
              bearing={this.state.bearing}
            />
          </Grid>
          <Grid item xs={12}>
            <Log log={this.state.log} />
          </Grid>
        </Grid>
        <audio id="tone440Hz" src={"./assets/sounds/440Hz.mp3"}></audio>
        <audio id="tone1kHz" src={"./assets/sounds/1kHz.mp3"}></audio>
      </LayoutMUI>
    );
  }
}

export default withRouter(Position);

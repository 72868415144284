import React, { Component } from "react";

class VerticalSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
    };
    this.slideRef = React.createRef();
    this.handleSlideChange = this.handleSlideChange.bind(this);
  }

  componentDidMount() {
    this.slideRef.current.addEventListener("scroll", this.handleSlideChange);
  }

  componentWillUnmount() {
    this.slideRef.current.removeEventListener("scroll", this.handleSlideChange);
  }

  handleSlideChange() {
    const { scrollTop } = this.slideRef.current;
    const slideIndex = Math.round(scrollTop / window.innerHeight);
    this.setState({
      activeSlide: slideIndex,
    });
  }

  handleSlideEnd() {
    const { activeSlide } = this.state;
    const slidePosition = activeSlide * window.innerHeight;
    this.slideRef.current.scrollTo({
      top: slidePosition,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div
        className="vSlider"
        ref={this.slideRef}
        onScroll={this.handleSlideChange}
        onTouchEnd={() => this.handleSlideEnd()}
        onWheel={() => {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.handleSlideEnd();
          }, 40);
        }}
      >
        {this.props.slides.map((slide, index) => (
          <div id={"slide"+index} className="vSlide" key={index}>
            <h1 style={{ textAlign: "center" }}>{slide.title}</h1>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {slide.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Imagen ${index}`}
                  style={{ margin: "0" }}
                />
              ))}
            </div>
            <p style={{ textAlign: "center", padding: "0" }}>{slide.text}</p>
          </div>
        ))}
      </div>
    );
  }
}

export default VerticalSlider;

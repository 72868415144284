import React, { Component } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { FormattedMessage } from "react-intl";
import CheckIcon from "@mui/icons-material/Check";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default class FormNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="formNavigation">
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          color={"neutral"}
        >
          <Button
            onClick={() => this.props.handleFormNav(false)}
            startIcon={<NavigateBeforeIcon />}
          >
            Previous
          </Button>
          <Button
            onClick={() => {
              this.props.handleFormNav(true);
            }}
            endIcon={<CheckIcon />}
          >
            <FormattedMessage
              id={this.props.okButtonLabelId}
              defaultMessage="Done"
            />
          </Button>
          <Button
            onClick={() => this.props.handleFormNav(true)}
            endIcon={<NavigateNextIcon />}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

import React, { Component } from "react";

export default class summaryQuestionItemResults extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let responseValue = this.props.response.value;
    if (this.props.response.label === 'FILE') {
      responseValue = (<a href={process.env.REACT_APP_API_URL + "/api/download/"+this.props.formId+"/"+this.props.response.questionId+"/"+this.props.response.visitorHash}>{responseValue}</a>);
    }
    return <div className="responseValue">{responseValue}</div>;
  }
}

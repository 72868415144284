import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

axios.defaults.withCredentials = true;

function redirectToExternalURL() {
  window.location.href = process.env.REACT_APP_API_URL + "/api/federated/login/google";
}
class SigninForm extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      username: "",
      password: "",
      redirectTo: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.updateAppState({
      breadcrumbs: [],
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL + "/api/auth/signin", {
        username: this.state.username,
        password: this.state.password,
      })
      .then((response) => {
        if (response.status === 200) {
          // update App.js state
          this.props.updateAppState({
            user: response.data,
          });
          // update the state to redirect to home
          this.setState({
            redirectTo: "/",
          });
        } else {
          this.props.updateAppState({
            user: null,
          });
        }
      })
      .catch((error) => {
        // TODO handle UI error
        console.log("login error: ");
        console.log(error);
      });
  }

  getProperties() {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/properties")
      .then((response) => {
        if (response.status === 200) {
          console.log("getProperties response: ");
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("login error: ");
        console.log(error);
      });
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <form>
        <Grid
          container
          direction={"column"}
          spacing={2}
          className={"loginContainer"}
        >
          {" "}
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="username"
                name="username"
                value={this.state.username}
                onChange={this.handleChange}
                placeholder={formatMessage({ id: "word.email" })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="password"
                name="password"
                type={"password"}
                onChange={this.handleChange}
                placeholder={"Password"}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              onClick={this.handleSubmit}
              type={"submit"}
              variant={"contained"}
              color={"primary"}
            >
              <FormattedMessage id="app.login.label" defaultMessage="Login" />
            </Button>
            <Link to={"/signup"}>
              <FormattedMessage id="app.signup.label" defaultMessage="Signup" />
            </Link>
            {/* FIXME continue with google auth */}
            <Link onClick={redirectToExternalURL}>
              Google
            </Link>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default injectIntl(SigninForm);

import React, { Component } from "react";
import SigninForm from "./signinForm.component";
import LoggedInUser from "./loggedInUser.component";
import AppContext from "../utilities/context";
import LayoutMUI from "./layoutMUI.component";

function LoggedStatus(props) {
  const context = React.useContext(AppContext);
  if (context.user && context.user.id) {
    return (
      <LoggedInUser user={context.user} updateAppState={props.updateAppState} />
    );
  }
  return <SigninForm updateAppState={props.updateAppState} />;
}

export default class Login extends Component {
  componentDidMount() {
    this.props.updateAppState({
      breadcrumbs: [],
    });
  }

  render() {
    return (
      <LayoutMUI>
        <LoggedStatus updateAppState={this.props.updateAppState} />
      </LayoutMUI>
    );
  }
}

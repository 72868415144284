import React, { Component } from "react";
// import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

export default class questionListItem extends Component {
  render() {
    //BUG the onClick is not correct in layout, you need to click outside the button
    return (
      <Grid
        item
        // className="questionListItem"
        onClick={() => this.props.setActiveQuestion(this.props.question.id)}
>
        {/* <TextField
          id={"QuestionTypeLabel" + this.props.question.id}
          label="Question"
          variant="outlined"
          value={this.props.question.text || "Question Text"}
          fullWidth
          disabled
          className="questionListItem"
        /> */}
        <div className="questionListItem">
        {this.props.question.text}
        </div>
      </Grid>
    );
  }
}

import React, { Component } from "react";
import LayoutMinimalMUI from "./layoutMinimalMUI.component";
import VerticalSlider from "./verticalSlider.component";

class ResponseVertical extends Component {
  render() {
    const slides = [
      {
        title: "Demo Slide 1",
        images: [
          "https://picsum.photos/500/300?random=1",
          "https://picsum.photos/500/300?random=2",
          "https://picsum.photos/500/300?random=3",
          "https://picsum.photos/500/300?random=4",
        ],
        text: "This is a demo slide with four images and some text.",
      },
      {
        title: "Demo Slide 2",
        images: [
          "https://picsum.photos/500/300?random=1",
          "https://picsum.photos/500/300?random=2",
          "https://picsum.photos/500/300?random=3",
          "https://picsum.photos/500/300?random=4",
        ],
        text: "This is a demo slide with four images and some text.",
      },
      {
        title: "Demo Slide 3",
        images: [
          "https://picsum.photos/500/300?random=1",
          "https://picsum.photos/500/300?random=2",
          "https://picsum.photos/500/300?random=3",
          "https://picsum.photos/500/300?random=4",
        ],
        text: "This is a demo slide with four images and some text.",
      },
      {
        title: "Demo Slide 4",
        images: [
          "https://picsum.photos/500/300?random=1",
          "https://picsum.photos/500/300?random=2",
          "https://picsum.photos/500/300?random=3",
          "https://picsum.photos/500/300?random=4",
        ],
        text: "This is a demo slide with four images and some text.",
      },
    ];

    return (
      <LayoutMinimalMUI>
        <VerticalSlider slides={slides} />
      </LayoutMinimalMUI>
    );
  }
}

export default ResponseVertical;

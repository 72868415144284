import React, { Component } from "react";
import LayoutMUI from "./layoutMUI.component";
import { FormattedMessage, injectIntl } from "react-intl";
import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import Link from "@mui/material/Link";
import { Helmet } from "react-helmet";

class Terms extends Component {
  render() {
    // const { formatMessage } = this.props.intl;
    return (
      <LayoutMUI>
        <Helmet>
          <title>ReviewLab Terms & Conditions</title>
          <meta name="description" content="The Terms & Conditions to Elevate Your Data Collection" />
          <meta property="og:title" content="ReviewLab Terms & Conditions  - helmet og_title" />
          <meta property="og:description" content="The Terms & Conditions to Elevate Your Data Collection - helmet og_desc" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://reviewlab.pro" />
          <meta property="og:image" content="https://reviewlab.pro/plopi_solo_iso200x200.png" />
        </Helmet>
        <Container component="main" sx={{ pt: 4, pb: 6 }}>
          <Grid container spacing={5} alignItems="flex-end">
            <FormattedMessage
              id={"terms.text"}
              defaultMessage={"Terms of Service"}
              values={{
                lineBreak: <br />,
              }}
            />
            <p><FormattedMessage
              id={"freepik.disclaimer"}
            /> <a href="https://www.freepik.com/" target={"_blank"} rel={"noreferrer"}>Freepik</a></p>
          </Grid>
        </Container>
      </LayoutMUI>
    );
  }
}

export default injectIntl(Terms);

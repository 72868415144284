import React, { Component } from "react";
import axios from "axios";
import OptionItem from "./optionItem.component";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

function OptionsList(props) {
  if (props.question.options) {
    return props.question.options.map((d) => (
      <Grid item key={d.id}>
        <OptionItem
          option={d}
          handleOptionsChange={props.handleOptionsChange}
          removeOption={props.removeOption}
        />
      </Grid>
    ));
  }
  return <></>;
}

export default class questionDetail extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.activeQuestion) {
      const questionIndex = this.props.questions.findIndex(
        (question) => question.id === parseInt(this.props.activeQuestion)
      );
      // let questionData = Object.assign({}, this.props.questions[questionIndex]);
      let questionData = JSON.parse(
        JSON.stringify(this.props.questions[questionIndex])
      );
      if (questionData.definition) delete questionData.definition;
      if (questionData.options) delete questionData.options;
      axios
        .put(
          process.env.REACT_APP_API_URL +
            "/api/questions/" +
            this.props.activeQuestion,
          questionData
        )
        .then((response) => {
          if (response.status === 200) {
          } else {
          }
        })
        .catch((error) => {
          // TODO handle UI error
          console.log("login error: ");
          console.log(error);
        });
    }
  }

  render() {
    const questionIndex = this.props.questions.findIndex(
      (question) => question.id === parseInt(this.props.activeQuestion)
    );
    if (this.props.activeQuestion) {
      let question = this.props.questions[questionIndex];
      let questionInput = (
        <Grid item>
          <TextField
            id="QuestionTypeLabel"
            inputProps={{ name: "text" }}
            label="Type your question"
            variant="outlined"
            value={question.text}
            onChange={this.props.handleChange}
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
      );
      switch (question.definition.label) {
        case "SHORT_TEXT":
          break;
        case "NUMBER":
        case "RATING":
          break;
        case "MULTIPLE_CHOICE":
          questionInput = (
            <Grid item>
              <TextField
                id="QuestionTypeLabel"
                inputProps={{ name: "text" }}
                label="Type your question"
                variant="outlined"
                value={question.text}
                onChange={this.props.handleChange}
                fullWidth
                multiline
                rows={3}
              />
              <div className="options-list">
                <Grid container direction={"column"} spacing={2}>
                  <OptionsList
                    question={question}
                    handleOptionsChange={this.props.handleOptionsChange}
                    removeOption={this.props.removeOption}
                  />
                  <Grid item>
                    <Button
                      onClick={this.props.createOption}
                      className={"btn btn-primary"}
                      id={"button-add-option"}
                    >
                      + Create Option
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
          break;
        default:
      }
      return <>{questionInput}</>;
    }
    return <></>;
  }
}

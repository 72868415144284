import React, { Component } from "react";
import axios from "axios";
import SummaryQuestionItemResults from "./summaryQuestionItemResults.component";
import SummaryQuestionItemGraph from "./summaryQuestionItemGraph.component";
import ResponsesHighlights from "./responsesHighlights.component";
import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function ResponsesList(props) {
  if (props.responses) {
    return props.responses.map((d) => (
      <SummaryQuestionItemResults key={d.visitorHash + d.value} response={d} formId={props.formId} />
    ));
  }
  return <li>Nada aun</li>;
}

export default class summaryQuestionItem extends Component {
  constructor() {
    super();
    this.state = {
      cardAnchorEl: null,
      cardMenuOpened: false,
    };
    this.setOpen = this.setOpen.bind(this);
  }

  componentDidMount() {}

  setOpen = (status, anchor) => {
    this.setState({ cardAnchorEl: anchor });
    this.setState({ cardMenuOpened: status });
  };

  showMenu = (e) => {
    this.setOpen(true, e.currentTarget);
  };

  hideMenu = (e) => {
    this.setOpen(false, null);
  };

  downloadCSV = (questionId) => {
    this.hideMenu();
    axios
      .get(
        process.env.REACT_APP_API_URL + "/api/result/question/" + questionId,
        { responseType: "blob" }
      )
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "data.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  render() {
    return (
      <Grid item xs={12} sm={6}>
        <Card>
          <CardHeader
            action={
              <IconButton aria-label="settings" onClick={this.showMenu}>
                <MoreVertIcon />
              </IconButton>
            }
            title={this.props.question.text}
          />
          <Menu
            id="basic-menu"
            anchorEl={this.state.cardAnchorEl}
            open={this.state.cardMenuOpened}
            onClose={this.hideMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => this.downloadCSV(this.props.question.id)}>
              Download CSV
            </MenuItem>
          </Menu>
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <ResponsesHighlights
                  question={this.props.question}
                  responses={this.props.responses}
                />
                <ResponsesList responses={this.props.responses} formId={this.props.formId} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SummaryQuestionItemGraph
                  question={this.props.question}
                  responses={this.props.responses}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

import React, { Component } from "react";
// import { Navigate } from 'react-router-dom';
import AppContext from "../utilities/context";
import { FormattedMessage } from "react-intl";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../detailLogo.png";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Helmet } from "react-helmet";

const reviewLabTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#6b7a8f",
      darker: "#053e85",
      contrastText: "#ffcc00",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const drawerWidth = 240;

function BreadcrumbsItems(props) {
  const context = React.useContext(AppContext);
  return context.breadcrumbs.map((d, index) => (
    <Link key={index} to={d.url}>
      {d.label}
    </Link>
  ));
}

function Preloader(props) {
  const context = React.useContext(AppContext);
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={context.preloader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

function UserMenu(props) {
  const context = React.useContext(AppContext);
  let navItems;
  if (context.user && context.user.id) {
    navItems = [
      {
        label: "Home",
        link: "/",
        localeId: "app.header.home.label",
        public: true,
      },
      {
        label: "Pricing",
        link: "/pricing",
        localeId: "app.header.pricing.label",
        public: true,
      },
      {
        label: "Workspace",
        link: "/forms",
        localeId: "app.content.workspace.label",
        public: false,
      },
      {
        label: "Account",
        link: "/login",
        localeId: "app.header.account.label",
        public: true,
      },
    ];
  } else {
    navItems = [
      {
        label: "Home",
        link: "/",
        localeId: "app.header.home.label",
        public: true,
      },
      {
        label: "Pricing",
        link: "/pricing",
        localeId: "app.header.pricing.label",
        public: true,
      },
      {
        label: "Login",
        link: "/login",
        localeId: "app.header.login.label",
        public: true,
      },
    ];
  }
  if (props.drawer) {
    return navItems.map((item) => (
      <ListItem key={item.label} disablePadding>
        <Link key={item.label} to={item.link} className={"drawerMenu"}>
          <FormattedMessage id={item.localeId} defaultMessage={item.label} />
        </Link>
      </ListItem>
    ));
  } else {
    return navItems.map((item) => (
      <Link key={item.label} to={item.link} className={"headerMenu"}>
        <FormattedMessage id={item.localeId} defaultMessage={item.label} />
      </Link>
    ));
  }
}

class LayoutMUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      anchorEl: null,
      open: false,
    };
  }

  componentDidMount = () => {};

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  setAnchorEl = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handleLink = (location) => {
    //TODO change redirect using react-router-dom. Already tried it but didn't work.
    window.location.href = location;
    this.handleClose();
  };

  render() {
    const { window } = this.props;
    const { mobileOpen } = this.state;

    const drawer = (
      <Box onClick={this.handleDrawerToggle} sx={{ textAlign: "center" }}>
        <img src={logo} className="App-logo mrkPT1em" alt="logo" />
        <Typography variant="h6" sx={{ my: 2 }}>
          YouRank
        </Typography>
        <Divider />
        <List>
          <UserMenu drawer={true} />
        </List>
      </Box>
    );

    const container =
      window !== undefined ? () => window().document.body : undefined;

    return (
      <ThemeProvider theme={reviewLabTheme}>
        <Helmet>
          <title>:: YouRank ::</title>
          <meta name="description" content="Elevate Your Data Collection - helmet meta desc" />
          <meta property="og:title" content="YouRank  - helmet og_title" />
          <meta property="og:description" content="Elevate Your Data - helmet og_desc" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://yourank.online" />
          <meta property="og:image" content="https://yourank.online/plopi_solo_iso200x200.png" />
        </Helmet>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Preloader />
          <AppBar component="nav" color="primary" sx={{ height: "64px" }}>
            <Toolbar sx={{ height: "64px" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Link to={"/"}>
                <img src={logo} className="App-logo" alt="logo" />
              </Link>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
              >
                YouRank
              </Typography>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <UserMenu />
                <Link
                  id="basic-button"
                  aria-controls={this.state.open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? true : undefined}
                  onClick={this.setAnchorEl}
                  className={"headerMenu"}
                >
                  Support
                </Link>
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.anchorEl}
                  open={this.state.open}
                  onClose={this.handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      this.handleLink("/terms");
                    }}
                  >
                    Terms of Service
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.handleLink("/privacy");
                    }}
                  >
                    Privacy Policy
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
          <Container
            maxWidth={false}
            disableGutters={false}
            className={"mainContainer"}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              children={<BreadcrumbsItems />}
              className={"breadcrumbs"}
            />
            <Box component="main">{this.props.children}</Box>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }
}

export default LayoutMUI;

import React, { Component } from "react";
import LayoutMUI from "./layoutMUI.component";
import { FormattedMessage, injectIntl } from "react-intl";
import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import Link from "@mui/material/Link";

class Terms extends Component {
  render() {
    // const { formatMessage } = this.props.intl;
    return (
      <LayoutMUI>
        <Container component="main" sx={{ pt: 4, pb: 6 }}>
          <Grid container spacing={5} alignItems="flex-end">
            <FormattedMessage
              id={"privacy.text"}
              defaultMessage={"Privacy Policy"}
              values={{
                lineBreak: <br />,
              }}
            />
          </Grid>
        </Container>
      </LayoutMUI>
    );
  }
}

export default injectIntl(Terms);

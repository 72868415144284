import React, { Component } from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";

function QuestionConfig(props) {
  if (props.question.definition.config) {
    let questionConfig = [];
    props.question.definition.config.forEach(function (item, index) {
      let questionConfigOptions = [];
      item.options.forEach(function (optionItem, optionIndex) {
        questionConfigOptions.push(
          <option key={"defConfOpt" + optionIndex} value={optionItem.value}>
            <FormattedMessage
              id={optionItem.labelId}
              defaultMessage={optionItem.labelId}
            />
          </option>
        );
      });
      questionConfig.push(
        <Grid item key={"defConfGridKey" + index}>
          <FormControl key={"defConfKey" + index} fullWidth>
            <InputLabel id={"settingsConfigLabel" + index}>
              <FormattedMessage
                id={item.labelId}
                defaultMessage={item.labelId}
              />
            </InputLabel>
            <Select
              name={"config"}
              labelId={"settingsConfigLabel" + index}
              id={"settingsConfig" + index}
              value={props.question.config[index].value}
              label={item.labelId}
              onChange={(e) =>
                props.handleChange(e, {
                  "data-config-target": item.type,
                  "data-config-previous": JSON.stringify(props.question.config),
                })
              }
              children={questionConfigOptions}
              native={true}
            ></Select>
          </FormControl>
        </Grid>
      );
    });
    return questionConfig;
  }
  return <></>;
}
export default class QuestionSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    if (this.props.question) {
      let questionData = JSON.parse(JSON.stringify(this.props.question));
      if (questionData.definition) delete questionData.definition;
      if (questionData.options) delete questionData.options;
      axios
        .put(
          process.env.REACT_APP_API_URL +
            "/api/questions/" +
            this.props.question.id,
          questionData
        )
        .then((response) => {
          if (response.status === 200) {
          } else {
          }
        })
        .catch((error) => {
          // TODO handle UI error
          console.log("login error: ");
          console.log(error);
        });
    }
  }
//BUG hay un fucking bug al editar pregunta, a veces salta, a veces no.
  render() {
    return (
      <form>
        <Grid
          container
          direction={"column"}
          spacing={2}
          className={"loginContainer"}
        >
          <Grid item>
            <TextField
              id="QuestionTypeLabel"
              label="Type"
              variant="outlined"
              value={this.props.question.definition.description}
              fullWidth
            />
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Required</InputLabel>
              <Select
                name="required"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.props.question.required}
                label="Required"
                onChange={this.props.handleChange}
                native={true}
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="settingsActiveLabel">Active</InputLabel>
              <Select
                name="active"
                labelId="settingsActiveLabel"
                id="settingsActive"
                value={this.props.question.active}
                label="Active"
                onChange={this.props.handleChange}
                native={true}
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Select>
            </FormControl>
          </Grid>
          <QuestionConfig
            question={this.props.question}
            handleChange={this.props.handleChange}
          />
          <Grid item>
            <Button
              onClick={this.props.deleteQuestion}
              startIcon={<DeleteIcon />}
              variant={"contained"}
              color={"error"}
            >
              Delete Question
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

function HighlightsDetail(props) {
  if (props.question) {
    let highlightBody = "";
    switch (props.question.definition.label) {
      case "SHORT_TEXT":
        break;
      case "DATE_TIME":
        break;
      case "NUMBER":
      case "RATING":
      let total = 0;
        let totalResponses = 0;
        props.responses.forEach(function (item, index) {
          total += parseInt(item.value) * parseInt(item.total);
          totalResponses += parseInt(item.total);
        });
        if (totalResponses>0) {
          let average = Math.round((total / totalResponses) * 100) / 100;
          highlightBody = (
            <div className="highlightsDetail">
              <h4>
                <FormattedMessage
                  id="app.content.form.results.highlights.average.label"
                  defaultMessage="Average: "
                />
                <span>{average}</span>
              </h4>
            </div>
          );  
        }
        break;
      case "MULTIPLE_CHOICE":
        break;
      case "POSITION":
        break;
      default:
    }

    return <>{highlightBody}</>;
  }
  return <></>;
}
export default class responsesHighlights extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <HighlightsDetail
        responses={this.props.responses}
        question={this.props.question}
      />
    );
  }
}

import axios from "axios";

export const createHash = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const selectProps = (...props) => {
  return function (obj) {
    const newObj = {};
    props.forEach((name) => {
      newObj[name] = obj[name];
    });
    return newObj;
  };
};

export const getValueFromObjectsArray = (prop, object) => {
  var result = null;
  if (object) {
    const questionIndex = object.findIndex(
      (item) => item.type === prop
    );
    if (questionIndex >= 0) result = JSON.parse(object[questionIndex].value);  
  }
  return result;
};

export const logUserActivity = (props) => {
  axios
  .post(
    process.env.REACT_APP_API_URL +
      "/api/log/", {
        userId: props.userId,
        visitorHash: props.visitorHash,
        source: props.source,
        action: props.action,
        data: props.data,
        formId: props.formId
      }
  )
  .then((response) => {
  })
  .catch((error) => {
    console.log(error);
  });

  return null;
};
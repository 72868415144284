import React, { Component } from "react";
import ShortTextIcon from "@mui/icons-material/ShortText";
import NotesIcon from "@mui/icons-material/Notes";
import NumbersIcon from "@mui/icons-material/Numbers";
import RuleIcon from "@mui/icons-material/Rule";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CommentIcon from '@mui/icons-material/Comment';
import LinkIcon from '@mui/icons-material/Link';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from "@mui/material/Button";

const iconsMap = [];
iconsMap["ShortTextIcon"] = <ShortTextIcon />;
iconsMap["NotesIcon"] = <NotesIcon />;
iconsMap["NumbersIcon"] = <NumbersIcon />;
iconsMap["RuleIcon"] = <RuleIcon />;
iconsMap["CalendarMonthIcon"] = <CalendarMonthIcon />;
iconsMap["AccessTimeIcon"] = <AccessTimeIcon />;
iconsMap["EventIcon"] = <EventIcon />;
iconsMap["MyLocationIcon"] = <MyLocationIcon />;
iconsMap["StarOutlineIcon"] = <StarOutlineIcon />;
iconsMap["CommentIcon"] = <CommentIcon />;
iconsMap["LinkIcon"] = <LinkIcon />;
iconsMap["CloudUploadIcon"] = <CloudUploadIcon />;

export default class DefinitionListItem extends Component {
  render() {
    return (
      <div>
        <Button
          type="button"
          onClick={this.props.createQuestion}
          value={this.props.definition.id}
          variant="outlined"
        >
          {iconsMap[this.props.definition.icon]}&nbsp;
          {this.props.definition.description}
        </Button>
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { Routes, Route } from "react-router-dom";
// import { HelmetProvider } from 'react-helmet-async';
import AppContext from "./utilities/context";
import { IntlProvider } from "react-intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Spanish from "./lang/es.json";
import English from "./lang/en.json";
import Login from "./components/login.component";
import Home from "./components/home.component";
// import Calendar from "./components/calendar.component";
import Forms from "./components/forms.component";
import FormDetail from "./components/formDetail.component";
import Response from "./components/visitor/response.component";
import ResponseVertical from "./components/visitor/responseVertical.component";
import Results from "./components/results.component";
import Position from "./components/visitor/position.component";
import SignupFormComponent from "./components/signupForm.component";
import Pricing from "./components/pricing.component";
import Privacy from "./components/privacy.component";
import Terms from "./components/terms.component";

// import { createTheme } from "@mui/material/styles";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

axios.defaults.withCredentials = true;

const locales = ["es", "es-ar", "es-es"];
let locale;
let lang;

// const reviewLabTheme = createTheme({
//   status: {
//     danger: "#e53e3e",
//   },
//   palette: {
//     primary: {
//       main: "#6b7a8f",
//       darker: "#053e85",
//       contrastText: "#ffcc00",
//     },
//     neutral: {
//       main: "#64748B",
//       contrastText: "#fff",
//     },
//   },
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      definitions: null,
      breadcrumbs: [],
      preloader: false,
    };

    this.appRef = React.createRef();
    this.getUser = this.getUser.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.updateAppState = this.updateAppState.bind(this);
  }

  componentDidMount() {
    // TODO maybe replace for some router method, but I already tried the withRouter and it didn't work
    if (window.location.pathname.search(/\/response/) === -1) {
      this.getUser();
      this.getDefinitions();
    }
    // this.updateAppState({
    //   theme: reviewLabTheme.palette,
    // });
  }

  updateAppState(object) {
    this.setState(object);
  }

  getUser() {
    if (!this.state.user) {
      axios
        .get(process.env.REACT_APP_API_URL + "/api/test/user/")
        .then((response) => {
          if (response.data.id) {
            this.setState({
              user: response.data,
            });
          }
        })
        .catch((error) => {
          this.setState({
            user: null,
          });
        });
    }
    return this.state.user;
  }

  getDefinitions() {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/definitions/")
      .then((response) => {
        if (response.data) {
          this.setState({
            definitions: response.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          definitions: null,
        });
      });
    return this.state.definitions;
  }

  render() {
    locale = this.state.user ? this.state.user.locale : navigator.language.toLowerCase();
    if (locales.includes(locale)) {
      lang = Spanish;
    } else {
      lang = English;
      locale = "en";
    }
    return (
      <div className="App d-flex flex-column h-100">
        {/* <HelmetProvider> */}
        <AppContext.Provider value={this.state}>
          <IntlProvider locale={locale} messages={lang}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<Home updateAppState={this.updateAppState} />}
                />
                {/* <Route exact path="/calendar" element={<Calendar />} /> */}
                <Route
                  exact
                  path="/forms"
                  element={<Forms updateAppState={this.updateAppState} />}
                />
                <Route
                  exact
                  path="/forms/to/:hash"
                  element={<FormDetail updateAppState={this.updateAppState} />}
                />
                <Route
                  exact
                  path="/results/to/:hash"
                  element={<Results updateAppState={this.updateAppState} />}
                />
                <Route
                  exact
                  path="/login"
                  element={
                    <Login
                      getUser={this.getUser}
                      updateAppState={this.updateAppState}
                    />
                  }
                />
                <Route
                  exact
                  path="/signup"
                  element={
                    <SignupFormComponent
                      getUser={this.getUser}
                      updateAppState={this.updateAppState}
                    />
                  }
                />
                <Route exact path="/response/to/:hash" element={<Response />} />
                <Route
                  exact
                  path="/responsev/to/:hash"
                  element={<ResponseVertical />}
                />
                <Route exact path="/position" element={<Position />} />
                <Route
                  exact
                  path="/pricing"
                  element={<Pricing updateAppState={this.updateAppState} />}
                />
                <Route
                  exact
                  path="/privacy"
                  element={<Privacy updateAppState={this.updateAppState} />}
                />
                <Route
                  exact
                  path="/terms"
                  element={<Terms updateAppState={this.updateAppState} />}
                />
              </Routes>
            </LocalizationProvider>
          </IntlProvider>
        </AppContext.Provider>
        {/* </HelmetProvider> */}
      </div>
    );
  }
}

export default App;

import { createContext } from "react";

export const appData = {
  user: null,
  definitions: null,
  theme: null
};

const AppContext = createContext({
  user: appData.user,
  setUser: () => {},
  definitions: appData.definitions,
  setDefinitions: () => {},
  theme: appData.theme,
  setTheme: () => {},
});

export default AppContext;
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default class FormSubMenu extends Component {
  downloadCSV = (formId) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/result/global/" + formId, {
        responseType: "blob",
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "data.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  render() {
    return (
      <ul className="formSubMenu">
        <li>
          <Link to={"/forms/to/" + this.props.form.hash} className="nav-link">
            <FormattedMessage
              id="app.content.form.header.details.label"
              defaultMessage="Details"
            />
          </Link>
        </li>
        <li>
          <Link to={"/results/to/" + this.props.form.hash} className="nav-link">
            <FormattedMessage
              id="app.content.form.header.results.label"
              defaultMessage="Results"
            />
          </Link>
        </li>
        <li>
          <Link
            to={"/response/to/" + this.props.form.hash}
            className="nav-link"
            target={"_blank"}
          >
            <FormattedMessage
              id="app.content.form.header.preview.label"
              defaultMessage="Preview"
            />
          </Link>
        </li>
        <li>
          <button className="link" onClick={() => this.downloadCSV(this.props.form.id)}>
          <FormattedMessage
            id="app.content.form.header.export.label"
            defaultMessage="Export CSV"
          />
          </button>
        </li>
      </ul>
    );
  }
}

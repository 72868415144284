import React, { Component } from "react";
import QuestionSettingsForm from "./questionSettingsForm.component";

function QuestionSettingsData(props) {
  if (props.activeQuestion) {
    const questionIndex = props.form.questions.findIndex(
      (question) => question.id === parseInt(props.activeQuestion)
    );
    return (
      <QuestionSettingsForm
        question={props.form.questions[questionIndex]}
        handleChange={props.handleChange}
        deleteQuestion={props.deleteQuestion}
      />
    );
  }
  return <></>;
}

export default class QuestionSettings extends Component {
  render() {
    return (
        <QuestionSettingsData
          form={this.props.form}
          activeQuestion={this.props.activeQuestion}
          handleChange={this.props.handleChange}
          deleteQuestion={this.props.deleteQuestion}
        />
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default class OptionItem extends Component {
  componentDidUpdate() {
    if (this.props.option) {
      let optionData = JSON.parse(JSON.stringify(this.props.option));
      axios
        .put(
          process.env.REACT_APP_API_URL +
            "/api/options/" +
            this.props.option.id,
          optionData
        )
        .then((response) => {
          if (response.status === 200) {
          } else {
          }
        })
        .catch((error) => {
          // TODO handle UI error
          console.log("login error: ");
          console.log(error);
        });
    }
  }

  render() {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor={"option"+this.props.option.id}>
          Option {this.props.option.order}
        </InputLabel>
        <OutlinedInput
          id={"option"+this.props.option.id}
          type={"text"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  this.props.removeOption(this.props.option.id);
                }}
                edge="end"
                data-option-id={this.props.option.id}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </InputAdornment>
          }
          label="Option"
          inputProps={{
            name: "option",
            value: this.props.option.text,
            "data-option-id": this.props.option.id,
            placeholder: "Option " + this.props.option.order,
          }}
          onChange={this.props.handleOptionsChange}
        />
      </FormControl>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import LayoutMUI from "./layoutMUI.component";
import FormListItem from "./formListItem.component";

function FormModal(props) {
  if (props.forms) {
    const formIndex = props.forms.findIndex(
      (form) => form.id === parseInt(props.formId)
    );
    let form = props.forms[formIndex];
    let formName = form ? form.name : "";
    let formDesc = form ? form.description : "";
    let showTitle = form ? form.showTitle : false;
    let showDescription = form ? form.showDescription : false;
    return (
      <Dialog open={props.open} onClose={props.handleClose} fullWidth={true}>
        <DialogTitle>Form details</DialogTitle>
        <DialogContent className="mrkPT03em">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="formName">Name</InputLabel>
                <Input
                  id="formName"
                  type="text"
                  name="name"
                  placeholder="Form name"
                  value={formName}
                  onChange={props.handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="formDescription"
                  name="description"
                  value={formDesc}
                  onChange={props.handleChange}
                  placeholder={"Description"}
                  multiline
                  rows={3}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Show Title</InputLabel>
              <Select
                name="showTitle"
                labelId="select-showTitle-label"
                id="select-showTitle"
                value={showTitle}
                label="Show Title"
                onChange={props.handleChange}
                native={true}
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Show Description</InputLabel>
              <Select
                name="showDescription"
                labelId="select-showDescription-label"
                id="select-showDescription"
                value={showDescription}
                label="Show Description"
                onChange={props.handleChange}
                native={true}
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Select>
            </FormControl>    
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.deleteForm} variant="outlined" color="error">
            {"Delete"}
          </Button>
          <Button onClick={props.handleClose}>
            {props.closeLabel || "Close"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return <></>;
}

function FormList(props) {
  if (props.forms) {
    return props.forms.map((d) => (
      <FormListItem key={d.id} form={d} editForm={props.editForm} />
    ));
  }
  return <li></li>;
}

export default class Forms extends Component {
  constructor() {
    super();
    this.state = {
      forms: null,
      activeFormId: null,
      open: false,
    };
    this.getForms = this.getForms.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createForm = this.createForm.bind(this);
    this.deleteForm = this.deleteForm.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  setOpen = (status) => {
    if (status) {
      this.setState({ open: status });
    } else {
      this.setState({ open: status, activeFormId: null });
    }
  };

  handleClickOpen = () => {
    this.setOpen(true);
  };

  handleClose = () => {
    this.setOpen(false);
  };

  componentDidMount() {
    this.getForms();
    this.props.updateAppState({
      breadcrumbs: [{ label: "Home", url: "/" }],
    });
  }

  getForms(showModal = false) {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/forms/")
      .then((response) => {
        if (showModal) {
          this.setState({ forms: response.data }, () => this.setOpen(true));
        } else {
          this.setState({ forms: response.data });
        }
      })
      .catch((error) => {
        this.setState({ forms: null });
        console.log(error);
      });
  }

  editForm = (id) => {
    this.setState({ open: true, activeFormId: id });
  };

  handleChange(event) {
    let forms = this.state.forms;
    const formIndex = forms.findIndex(
      (form) => form.id === parseInt(this.state.activeFormId)
    );
    let form = forms[formIndex];
    form[event.target.name] = event.target.value;

    this.setState({ forms: forms }, () => {
      this.updateForm(form);
    });
  }

  createForm() {
    this.props.updateAppState({ preloader: true });
    console.log("preloader: true");
    const form = { name: "", description: "" };
    let custonHeaders = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/api/forms/", form, custonHeaders)
      .then((response) => {
        if (response.status === 200) {
          this.setState(
            {
              activeFormId: response.data.id,
            },
            () => {
              this.getForms(true);
            }
          );
          this.setOpen(true);
          this.props.updateAppState({ preloader: false });
          console.log("preloader: false");
        } else {
        }
      })
      .catch((error) => {
        // TODO handle UI error
        console.log("Error:");
        console.log(error);
      });
  }

  deleteForm() {
    axios
      .delete(
        process.env.REACT_APP_API_URL + "/api/forms/" + this.state.activeFormId
      )
      .then((response) => {
        if (response.status === 200) {
          this.getForms();
          this.handleClose();
        } else {
        }
      })
      .catch((error) => {
        // TODO handle UI error
        console.log("Error:");
        console.log(error);
      });
  }

  updateForm(form) {
    let custonHeaders = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .put(
        process.env.REACT_APP_API_URL + "/api/forms/" + form.id,
        form,
        custonHeaders
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
        } else {
        }
      })
      .catch((error) => {
        // TODO handle UI error
        console.log("Error:");
        console.log(error);
      });
  }

  render() {
    return (
      <LayoutMUI>
        <Container maxWidth={false} disableGutters>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4">
              <FormattedMessage
                id={"app.content.workspace.label"}
                defaultMessage={"Workspace"}
              />
            </Typography>
            <Button variant="outlined" onClick={this.createForm}>
              +{" "}
              <FormattedMessage
                id={"app.content.workspace.form.create.label"}
                defaultMessage={"Create Form"}
              />
            </Button>
            <FormModal
              handleChange={this.handleChange}
              formId={this.state.activeFormId}
              forms={this.state.forms}
              deleteForm={this.deleteForm}
              open={this.state.open}
              handleClose={this.handleClose}
            />
          </Box>
          <Grid container spacing={2} className={"mrkPT1em"}>
            <FormList forms={this.state.forms} editForm={this.editForm} />
          </Grid>
        </Container>
      </LayoutMUI>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import LayoutMUI from "./layoutMUI.component";
import withRouter from "../utilities/withRouter";
import Summary from "./summary.component";

function SummaryBoard(props) {
  if (props.form) {
    return (
      <Summary
        form={props.form}
        responses={props.responses}
        visits={props.visits}
      />
    );
  }
  return <></>;
}

class Results extends Component {
  constructor() {
    super();
    this.state = {};
    this.getResults = this.getResults.bind(this);
  }

  componentDidMount() {
    this.getResults();
    this.props.updateAppState({
      breadcrumbs: [
        { label: "Home", url: "/" },
        { label: "Workspace", url: "/forms" },
      ],
    });
  }

  getResults() {
    axios
      .get(
        process.env.REACT_APP_API_URL + "/api/result/" + this.props.params.hash
      )
      .then((response) => {
        this.setState({ form: response.data.form });
        this.setState({ responses: response.data.responses });
        this.setState({ visits: response.data.visits });
      })
      .catch((error) => {
        this.setState({ form: null });
        console.log(error);
      });
  }

  render() {
    return (
      <LayoutMUI>
        <SummaryBoard
          form={this.state.form}
          responses={this.state.responses}
          visits={this.state.visits}
        />
      </LayoutMUI>
    );
  }
}

export default withRouter(Results);

import React, { Component } from "react";
import axios from "axios";
import LayoutMUI from "./layoutMUI.component";
import { Link, Navigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage, injectIntl } from "react-intl";
import * as Validator from "validatorjs";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

axios.defaults.withCredentials = true;

class SignupForm extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      email: "",
      password: "",
      redirectTo: false,
      recaptchaValue: false,
      showAlert: false,
      alertMessage: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.reCaptchaOnChange = this.reCaptchaOnChange.bind(this);
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    // const { formatMessage } = this.props.intl;
    this.props.updateAppState({
      breadcrumbs: [],
    });
    // this.setState({ showAlert: true, alertMessage: formatMessage({ id: "word.warningBeta" }) });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { formatMessage } = this.props.intl;
    let validationData = {
      email: this.state.email,
      password: this.state.password,
    };
    let validationRules = {
      email: "required|email",
      password: "required",
    };
    let validation = new Validator(validationData, validationRules);

    const recaptchaValue = this.recaptchaRef.current.getValue();
    let captchaValidated =
      recaptchaValue === this.state.recaptchaValue ? true : false;

    if (validation.passes() && captchaValidated) {
      axios
        .post(process.env.REACT_APP_API_URL + "/api/auth/signup", {
          email: this.state.email,
          password: this.state.password,
          username: this.state.email,
        })
        .then((response) => {
          if (response.status === 200) {
            this.props.updateAppState({
              user: response.data,
            });
            this.setState({
              redirectTo: "/login",
            });
          } else {
            this.props.updateAppState({
              user: null,
            });
          }
        })
        .catch((error) => {
          this.setState({
            showAlert: true,
            alertMessage: error.response.data.message,
          });
        });
    } else {
      let alertMessage = "";
      alertMessage += validation.errors.first("email")
        ? validation.errors.first("email") + " "
        : "";
      alertMessage += validation.errors.first("password")
        ? validation.errors.first("password") + " "
        : "";
      alertMessage += !captchaValidated
        ? formatMessage({ id: "app.signUpValidationRecaptcha" })
        : "";
      this.setState({ showAlert: true, alertMessage: alertMessage });
    }
  }

  reCaptchaOnChange(value) {
    this.setState({
      recaptchaValue: value,
    });
  }

  hideAlert = (e) => {
    this.setState({ showAlert: false, alertMessage: null });
  };

  render() {
    const { formatMessage } = this.props.intl;
    if (this.state.redirectTo) return <Navigate to={this.state.redirectTo} />;
    return (
      <LayoutMUI>
        <Collapse in={this.state.showAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  this.hideAlert();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <p>{this.state.alertMessage}</p>
          </Alert>
        </Collapse>
        <form>
        <Grid
          container
          direction={"column"}
          spacing={2}
          className={"loginContainer"}
        >
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder={formatMessage({ id: "word.email" })}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  id="password"
                  name="password"
                  type={"password"}
                  onChange={this.handleChange}
                  placeholder={"Password"}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={this.reCaptchaOnChange}
                ref={this.recaptchaRef}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={this.handleSubmit}
                type="submit"
                variant={"contained"}
                color={"primary"}
              >
                <FormattedMessage
                  id="app.signup.label"
                  defaultMessage="Sign Up"
                />
              </Button>
              <Link to={"/login"}>
                <FormattedMessage id="app.login.label" defaultMessage="Login" />
              </Link>
            </Grid>
        </Grid>
        </form>
      </LayoutMUI>
    );
  }
}

export default injectIntl(SignupForm);

import React, { Component } from "react";
import Chart from "react-apexcharts";
import PositionMap from "./map.component";
import { FormattedMessage } from "react-intl";

//TODO merkurio: verificar porque en prod usar la columna config tipo JSON daba error y no localmente
// podria ser la version de node que creo es diferente de la local
// update: teniamos la misma version de node ¯\_(ツ)_/¯

function ChartWrapper(props) {
  if (props.question.definition.chart) {
    let chartSeries = [];
    let chartOptions = null;

    switch (props.question.definition.label) {
      case "SHORT_TEXT":
      case "LONG_TEXT":
        break;
      case "DATE_TIME":
        break;
      case "POSTIION":
        break;
      case "NUMBER":
      case "RATING":
        chartSeries = [
          {
            data: props.responses.map((response) => ({
              x: response.value,
              y: parseInt(response.total),
            })),
          },
        ];
        chartOptions = {
          chart: {
            width: 380,
            type: "pie",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        };
        break;
      case "MULTIPLE_CHOICE":
        chartSeries = props.responses.map((response) =>
          parseInt(response.total)
        );
        chartOptions = {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: props.responses.map((response) => response.value),
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        };
        break;
      default:
    }
    if (props.question.definition.chart === "map") {
      if (props.responses.length > 0) {
        const mapIsReadyCallback = (map) => {
          console.log(map);
        };
        return (
          <div className="mapWrapper">
            <PositionMap
              mapIsReadyCallback={mapIsReadyCallback}
              responses={props.responses}
            />
          </div>
        );
      }
    } else {
      return (
        <div>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type={props.question.definition.chart}
          />
        </div>
      );
    }
  }

  return (
    <div>
      <FormattedMessage
        id="app.content.form.results.chart.noChart"
        defaultMessage="No chart available"
      />
    </div>
  );
}

export default class SummaryQuestionItemGraph extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {}

  render() {
    return (
      <>
        <ChartWrapper
          question={this.props.question}
          responses={this.props.responses}
        />
      </>
    );
  }
}

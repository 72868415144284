import React, { Component } from "react";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

export default class SettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.user.locale,
      militaryTime: props.user.militaryTime,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL + "/api/user", {
        militaryTime: this.state.militaryTime,
        locale: this.state.locale,
      })
      .then((response) => {
        if (response.status === 200) {
          // update App.js state
          this.props.updateAppState({
            user: response.data,
          });
          // update the state to redirect to home
          this.setState({
            redirectTo: "/",
          });
        } else {
          this.props.updateAppState({
            user: null,
          });
        }
      })
      .catch((error) => {
        // TODO handle UI error
        console.log("login error: ");
        console.log(error);
      });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid
          container
          direction={"column"}
          spacing={2}
          className={"settingsContainer"}
        >
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="settingsMilLabel">Hour Format</InputLabel>
              <Select
                name="militaryTime"
                labelId="settingsMilLabel"
                id="settingsMil"
                value={this.state.militaryTime}
                label="Hour Format"
                onChange={this.handleChange}
                native={true}
              >
                <option value={false}>12h</option>
                <option value={true}>24h</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="settingsLangLabel">Language</InputLabel>
              <Select
                name="locale"
                labelId="settingsLangLabel"
                id="settingsLang"
                value={this.state.locale}
                label="Language"
                onChange={this.handleChange}
                native={true}
              >
                <option value={"en"}>English</option>
                <option value={"es"}>Español</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button type="submit" variant={"contained"} color={"primary"}>
              Save
            </Button>
            <Button
              onClick={this.props.logout}
              variant={"outlined"}
              color={"primary"}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

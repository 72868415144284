import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
// import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
// import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#FFFFFF",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default class FormListItem extends Component {
  render() {
    return (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <Item>
          <Typography variant="h6" className="ellipsis">
            {this.props.form.name}
          </Typography>
          <Typography paragraph className="ellipsis">
            {this.props.form.description}&nbsp;
          </Typography>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              onClick={() => {
                this.props.editForm(this.props.form.id);
              }}
            >
              <EditIcon />
            </Button>
            <Button href={"/forms/to/" + this.props.form.hash}>
              <DynamicFormIcon />
            </Button>
          </ButtonGroup>
        </Item>
      </Grid>
    );
  }
}
